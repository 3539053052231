import LeftArrow from "../../../Images/a.svg";
import RightArrow from "../../../Images/b.svg";
import BtnLeftArrow from "../../../Images/c.svg";
import BtnRightArrow from "../../../Images/d.svg";

const AboutUsVideoBanner = ({
  title,
  subtitle,
  description,
  video,
  extension,
}: any) => {
  const splitText = title?.split(" ");

  const restOfText =
    splitText?.length && splitText?.slice(0, splitText?.length - 2)?.join(" ");
  return (
    <>
      <div className="bg-white flex gap-8 justify-center items-center flex-col lg:flex-row py-[50px] lg:py-homepagePaddingLg px-homepagePaddingSm relative">
        <div className="lg:h-[30rem] lg:w-[28rem] flex  xl:h-[31.3rem] xl:w-[45rem]  relative z-[1]">
          {extension === ".mp4" ? (
            <div className="w-full h-full flex justify-center items-center overflow-hidden border-[#F47924] border-[3px] bg-black">
              <video
                src={video}
                playsInline
                className="h-full w-full object-contain"
                autoPlay
                muted
                loop
                onContextMenu={(e) => e.preventDefault()}
              />
            </div>
          ) : (
            <div className="w-full h-full flex justify-center items-center overflow-hidden bg-bluePrimary">
              <img className="h-full self-center" src={video} />
            </div>
          )}

          <img
            src={LeftArrow}
            alt="BackgroundImage"
            className="absolute scale-[0.6] lg:scale-100  top-0 -mt-[3.1rem] -ml-[3rem] -z-10"
          />
          <img
            src={RightArrow}
            alt="BackgroundImage"
            className="absolute  scale-[0.6] lg:scale-100 top-0  right-0 -mt-[3.1rem] -mr-[3rem] -z-10"
          />
          <img
            src={BtnLeftArrow}
            alt="BackgroundImage"
            className="absolute scale-[0.6] lg:scale-100 bottom-0 -mb-[3.1rem] -ml-[3.1rem] -z-10"
          />
          <img
            src={BtnRightArrow}
            alt="BackgroundImage"
            className="absolute scale-[0.6] lg:scale-100 bottom-0 right-0  -mb-[3.1rem] -mr-[3.1rem] -z-10"
          />
        </div>
        <div className="flex flex-col gap-[12.5px] lg:gap-[25px] roboto">
          <div className="text-[20px] lg:text-primaryHeading2xl font-black text-blueTertiary w-[210px] lg:w-[391px] lg:leading-[55px]">
            {restOfText}{" "}
            <span className="text-orangePrimary ">
              {splitText?.length && splitText[splitText?.length - 2]}{" "}
              {splitText?.length && splitText[splitText?.length - 1]}
            </span>
          </div>
          <div className="lg:text-[25px] source-sans font-bold text-blueTertiary lg:w-[500px]">
            {subtitle}
          </div>
          <div className="text-descriptionHeadingSm lg:text-[18px] lg:leading-[30px] lg:w-[408px] text-blueTertiary source-sans">
            {description}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsVideoBanner;
